.formWrapper{
    margin-bottom: 1rem;
}
.formLabel{ 
    display: block;
    font-weight: 500;
    font-size: .75rem;
    margin-bottom: 8px;
}
.imageWrapper{
    width: 64px;
    height: 64px;
    background: #dedede;
    padding: 5px;
    border-radius: 5px;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}