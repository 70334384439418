.formWrapper{
    margin-bottom: 1rem;
}
.formControl{ 
    display: block;
    width: 100%;
    padding: 1rem 1.5rem 0 1.5rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: rgba(10,16,61,.03);
    background-clip: padding-box;
    border: 1px solid #d9d9d9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    height: 250px;
    outline: 0;
}
.formControl::placeholder{ 
    color: #bebebe;
}
.formControl:focus{ 
    border-color: #000;
}
.formLabel{ 
    display: block;
    font-weight: 500;
    font-size: .75rem;
    margin-bottom: 8px;
}
.inputWrapper{
    position: relative;
}