:root{
  --primary : #9763f6;
  --secondary : #7149BB;

  --font-family: "sans-serif", "-apple-system", "BlinkMacSystemFont", "Helvetica Neue",
  "Ubuntu", "Roboto", "Segoe UI", "Arial", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  --color-primary-main: #9763f6;
  --color-primary-shades-press: #462D74;
  --color-primary-shades-hover: #7149bb;
  --color-primary-shades-purple: #9c6def;
  --color-primary-shades-purple-light: #cbb1fb;
  --color-primary-opacity-5: #faf7ff;
  --color-primary-opacity-10: #f5effe;
  --color-primary-opacity-50: #cbb1fb;
  --color-primary-opacity-75: #b18af8;
  --color-primary-linear: linear-gradient(0deg, #7730fa 0%, #9961ff 100%);

  --color-shadow: #eae9f0;

  --color-dark-main: #0a103d;
  --color-dark-opacity-3: #f9f8f9;
  --color-dark-opacity-5: #f3f3f5;
  --color-dark-opacity-10: #e7e7ec;
  --color-dark-opacity-25: #c2c3ce;
  --color-dark-opacity-50: #84879e;
  --color-dark-opacity-75: #474C6D;

  --color-grey-opacity-10: #e8e8eb;
  --color-grey-opacity-20: #d1d2d6;
  --color-grey-opacity-30: #babbc2;
  --color-grey-opacity-40: #a3a5ad;
  --color-grey-opacity-50: #8c8e99;

  --color-complementary-allow: #0fdf7b;
  --color-complementary-deny: #ff1f1f;
  --color-complementary-Info: #ffce1f;

  --font-size-display-1: 96px;
  --font-size-display-2: 72px;
  --font-size-display-3: 64px;
  --font-size-display-4: 56px;

  --font-size-headline-1: 48px;
  --font-size-headline-2: 40px;
  --font-size-headline-3: 32px;
  --font-size-headline-4: 24px;
  --font-size-headline-5: 20px;
  --font-size-headline-6: 18px;

  --font-size-title-1: 16px;

  --font-size-body-1: 14px;
  --font-size-body-2: 13px;
  
  --font-size-caption-1: 12px;
  --font-size-caption-2: 11px;
  --font-size-caption-3: 10px;

  --font-weight-black: 900;
  --font-weight-extra-bold: 800;
  --font-weight-bold: 700;
  --font-weight-semiBold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  --theme-radius : 8px;
}
body { 
  font-family: var(--font-family);
}
:root {
  --blue: #1890ff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #198754;
  --teal: #20c997;
  --cyan: #0dcaf0;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #9763f6;
  --secondary: #6c757d;
  --success: #198754;
  --info: #faf7ff;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #0a103d;
}
.Logo {
  background-image: url("./images/logo.svg");
  background-repeat: no-repeat;
  width: 8rem;
  height: 2.4rem;
  background-size: contain;
  background-position: center;
}
.ant-layout{
  background: white;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.ant-layout-sider {
  padding-top: 20px;
  padding-bottom: 20px;
  .Logo {
    margin: 0 auto 20px auto;
  }
}

.ant-layout-content {
  padding: 24px 0;
  margin: 0;
  min-height: calc(100vh - 48px);
  width: 100%;
}

.order-detail-description-card {
  .ant-descriptions-item-content {
      max-width: 500px;
  }
}

.filters {
  .ant-picker {
    width: 100%;
  }
}

.orders__table {
  .ant-table-cell-fix-right {
    width: 24px;
  }
}

.ant-table-content{
  width: 100%; 
  position: relative;
  overflow-x: scroll;
  table{
    width: 100%;
    .ant-table-tbody > tr > td{
      white-space: nowrap;
    }
    .ant-table-thead > tr > th{
      white-space: nowrap;
    }
  }
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
  width: 100%;
}
.ant-menu{
  height: calc(100% - 39px);
  overflow: auto;
  position: relative;
}
.hidden{
  display: none;
}
.leftNavbar{
  -webkit-flex: 0 0 290px !important;
  -moz-box-flex: 0;
  flex: 0 0 290px !important;
  position: relative;
  width: 290px !important;
  max-width: 290px !important;
}
.rightLayout{
  padding: 0;
  -webkit-flex: 1 0;
  -moz-box-flex: 1;
  flex: 1 0;
  max-width: 100%;
  .navbar{
    padding: 10px 24px;
    background-color: rgb(237 237 237 / 85%);
  }
  .innerContent{
    padding: 10px 24px;
    min-width: 1100px;
  }
  .footer{
    padding: 20px 24px;
  }
}
.openMenu {
  .leftNavbar{
    display: none;
  }
  .rightLayout{
    margin: 0;
  }
}



/* btn */
.menuButton {
	position: relative;
	display:  inline-block;
	width: 30px;
	height: 30px;
	margin: 0;
  padding: 0;
  background-color: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  &:after{
    display: none;
  }
  span {
    margin: 0 auto;
    position: relative;
    top: 0;
    -webkit-transition-duration: 0s; transition-duration: 0s;
    -webkit-transition-delay: 0.2s; transition-delay: 0.2s;
    &:before , &:after{
      position: absolute;
      content: '';
    }
    &:before {
      margin-top: -6px;
      -webkit-transition-property: margin, -webkit-transform; transition-property: margin, transform;
      -webkit-transition-duration: 0.2s; transition-duration: 0.2s;
      -webkit-transition-delay: 0.2s, 0s; transition-delay: 0.2s, 0s;
    }
    &:after {
      margin-top: 6px;
    }
  }
  span, span:before, span:after {
    width: 30px;
    height: 3px;
    background-color: #000;
    display: block;
  }
}   
.ant-btn-success{
  color: #fff;
  border-color: var(--success);
  background: var(--success);
  &:hover,&:focus,&:active{
    border-color: var(--success);
    background: white;
    color: var(--success);
  }
}
.ant-btn-orange{
  color: #fff;
  border-color: var(--orange);
  background: var(--orange);
  &:hover,&:focus,&:active{
    border-color: var(--orange);
    background: white;
    color: var(--orange);
  }
}
.ant-steps-item-icon , .ant-steps-item-title{
  cursor: pointer;
}
.formBuilder{
  min-height: 600px;
}


.ant-collapse {
  border:0;
  margin-top: 10px;
  .ant-collapse-item {
    border-radius: 0 !important;
    position: relative;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    margin: 5px 0;
    > .ant-collapse-header {
      background: #1890ff;
      color: #fff;
    }

    .ant-collapse-header-collapsible-only {
      .ant-collapse-header-text {

      }
    }

    &.ant-collapse-no-arrow {
      > .ant-collapse-header {

      }
    }
  }
}

.printibleContent{
  table{
    width: 100%;
    margin-bottom: 4rem;
    border: 2px solid #dedede;
    th{
      font-size: 30px;
      padding: 10px 10px 5px 10px;
    }
    td{
      padding: 10px 20px;
      font-size: 40px;
      line-height: 1.2;
      word-spacing: 6px;
      &:first-child{
        width: 250px;
      }
    }
    tbody{
      tr:last-child{
        td{
          padding-bottom: 20px;
        }
      }
    }
  }
  .customer{
  }
  .sender{

  }
}
.collapseWrapper{
  position: relative;
  .cargoBtn{
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 5;
  }
}
.cargoCode{
  border: 2px dashed black;
  text-align: center;
  padding: 40px 20px;
  border-radius: 20px;
  font-size: 30px;
  font-weight: bold;
}

.itemActions{
  display: flex;
  flex-wrap:nowrap;
  width: 100%;
  column-gap: 20px;
  .dates{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 20px;
    row-gap: 10px;

    flex: 0 0 auto;
    width: 30%;
    > *{
      &:nth-child(3){
        grid-column: 1/3;
      }
    }
  }
  .ant-picker , .ant-select{
    width: 100% !important;
  }
  > *{
    flex: 1 0;
  }
}
.ant-card-extra{
  width: 100%;
}
.button-success{
  color:black;
  background-color:var(--color-complementary-allow) !important;
  border-color:var(--color-complementary-allow) !important;
  &:hover{
    opacity: .7;
  }
}

.primaryTable{
  table{
    background-color: var(--cyan);
    color: white;
    tr{
      td{
        background-color: var(--cyan) !important;
        color: white !important;
      }
    }
  }
}